<template>
  <div>
    <!-- about nav begin -->
    <div class="tri-fd"></div>
    <div class="about--nav">
      <div class="about--nav--wrap">
        <div class="inner-wrap">
          <div class="pd">
            <div class="about--nav--item">
              <a href="#aboutUs">{{ $t("about.about_nav_about_us") }}</a>
            </div>
            <div class="about--nav--item">
              <a href="#aboutEvents">{{ $t("about.about_nav_past_events") }}</a>
            </div>

            <div class="about--nav--item">
              <a href="#aboutAck">{{ $t("about.about_nav_acknowledgment") }}</a>
            </div>
            <div class="about--nav--item">
              <a href="#aboutTeam">{{ $t("about.about_nav_team") }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end of about nav -->
  </div>
</template>
<script>
export default {
  name: "AboutNav",
  components: {},
  data() {
    return {};
  },
  beforeMount() {},

  methods: {}
};
</script>

<style lang="scss" scoped></style>
